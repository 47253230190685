import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
 * @typedef {{
 * organizationId: number | string;
 * limit?: number;
 * offset?: number;
 * search?: string;
 * getAllEntities?: boolean;
 * orderBy?: {
 *  column: 'FULLNAME',
 *  direction: 'ASC' | 'DESC';
 * }}} TVariables
 */

/**
 * @typedef {Promise<{
 * data: {
 *   nodes: {
 *    id: number | string;
 *    fullname: string;
 *    picture: {
 *      uri: string;
 *    } | null,
 *    all: boolean;
 *    exceptionIds: object[];
 *    accessNetwork: {
 *      markers: {
 *        ids: number[];
 *        all: boolean;
 *        exceptionIds: number[];
 *      };
 *      organizations: {
 *        ids: number[];
 *        all: boolean;
 *        exceptionIds: number[];
 *      };
 *    };
 *   }[]
 *  } | null,
 *  error?: string
 * }>} TResponse
 */

/**
 * @param {TVariables} variables
 * @returns {TResponse}
 */

export async function getEntitiesByOrganizationIdRepository(variables) {
  const query = gql`
    query getEntitiesByOrganizationId(
      $organizationId: ID!
      $limit: Int
      $offset: Int
      $search: String
      $getAllEntities: Boolean
      $orderBy: OrderByInput
    ) {
      node(id: $organizationId) {
        ... on Entity {
          id: dbId
          accessNetwork(networkId: $organizationId) {
            markers {
              ids
              all
              exceptionIds
            }
            organizations {
              ids
              all
              exceptionIds
            }
          }
          entities(
            limit: $limit
            offset: $offset
            search: $search
            getAllEntities: $getAllEntities
            orderBy: $orderBy
          ) {
            totalCount
            nodes {
              id: dbId
              fullname
              type
              picture {
                uri
              }
              all
              exceptionIds {
                id
                name
              }
            }
          }
        }
      }
    }
  `;

  const { data, error } = await Query(query, variables);
  return { data, error };
}
