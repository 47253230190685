import * as apolloDataSource from '../../../DataSource/ApolloDataSource';
import { getMarkersByOrganizationIdRepository } from '../GetMarkersByOrganizationId';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('given the getMarkersByOrganizationIdRepository function', () => {
  it('should call Query with the correct params', async () => {
    const QueryMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Query').mockImplementation(QueryMock);
    const variables = {
      organizationId: 'id',
      search: '',
      limit: 30,
      offset: 0,
    };

    await getMarkersByOrganizationIdRepository(variables);

    expect(apolloDataSource.Query).toHaveBeenCalledWith(undefined, {
      ...variables,
    });
  });

  it('should return data and errors', async () => {
    const response = {
      loading: false,
      data: 'data',
      errors: [],
      networkStatus: 7,
      stale: true,
    };

    jest.spyOn(apolloDataSource, 'Query').mockResolvedValue(response);
    const variables = {
      organizationId: 'id',
      search: '',
      limit: 30,
      offset: 0,
    };
    const result = await getMarkersByOrganizationIdRepository(variables);

    expect(result).toEqual(response);
  });
});
