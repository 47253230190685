/**
 * @param {{
 *    id: number,
 *    unleashStatus: {
 *      recipients_list_screen: boolean
 *      custom_media: boolean
 *    }
 * }} organization
 */
export function hasCustomFileRecipientsModal(organization) {
  if (!organization) return false;

  const hasCustomFileFeature = organization?.unleashStatus?.custom_media;
  const hasCustomFileRecipients =
    organization?.unleashStatus?.recipients_list_screen;

  return hasCustomFileFeature && hasCustomFileRecipients;
}
