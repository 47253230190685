import { getEntitiesByOrganizationIdUseCase } from '../GetEntitiesByOrganizationId';
import * as DataSource from '../../../../Data/DataSource/ApolloDataSource';

describe('getEntitiesByOrganizationIdUseCase', () => {
  beforeEach(() => {
    jest.spyOn(DataSource, 'Query').mockImplementation(() => ({
      data: {
        node: {
          id: 1,
          accessNetwork: {
            markers: {
              ids: [1],
              all: true,
              exceptionIds: [1],
            },
            organizations: {
              ids: [1],
              all: true,
              exceptionIds: [1],
            },
          },
          entities: {
            nodes: [
              {
                id: 1,
                fullname: 'test',
                type: 'test',
                picture: {
                  uri: 'test',
                },
                all: true,
                exceptionIds: [1],
              },
            ],
            totalCount: 1,
          },
        },
      },
    }));
  });

  it('should be able to call use case correctly', async () => {
    const variables = {
      organizationId: 1,
    };

    const response = await getEntitiesByOrganizationIdUseCase(variables);

    expect(response).toEqual({
      id: 1,
      accessNetwork: {
        markers: {
          ids: [1],
          all: true,
          exceptionIds: [1],
        },
        organizations: {
          ids: [1],
          all: true,
          exceptionIds: [1],
        },
      },
      entities: {
        nodes: [
          {
            id: 1,
            fullname: 'test',
            type: 'test',
            picture: 'test',
            all: true,
            exceptionIds: [1],
          },
        ],
        totalCount: 1,
      },
      error: undefined,
    });
  });
});
