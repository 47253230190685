import { hasCustomFileRecipientsModal } from '../HasCustomFileRecipientsModal';

describe('Given the hasCustomFileRecipientsModal function', () => {
  let organization;

  beforeEach(() => {
    jest.clearAllMocks();

    organization = {
      id: 1,
      unleashStatus: {
        recipients_list_screen: true,
        custom_media: true,
      },
    };
  });

  it('should return false when organization is not provided', () => {
    expect(hasCustomFileRecipientsModal()).toBe(false);
  });

  it('should return true when organization has custom media feature and recipients modal', () => {
    expect(hasCustomFileRecipientsModal(organization)).toBe(true);
  });

  it('should return false when organization has feature and not has recipients modal', () => {
    organization.unleashStatus.recipients_list_screen = false;
    expect(hasCustomFileRecipientsModal(organization)).toBe(false);
  });

  it('should return false when organization has feature and not has custom media', () => {
    organization.unleashStatus.custom_media = false;
    expect(hasCustomFileRecipientsModal(organization)).toBe(false);
  });
});
