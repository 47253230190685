import { getEntitiesByOrganizationIdRepository } from '../GetEntitiesByOrganizationId';
import * as DataSource from '../../../DataSource/ApolloDataSource';
import gql from 'graphql-tag';

describe('getEntitiesByOrganizationIdRepository', () => {
  let querySpy;

  beforeEach(() => {
    querySpy = jest.spyOn(DataSource, 'Query').mockImplementation(() => ({
      data: { nodes: [{ id: 1 }] },
    }));
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  it('should call query with the correct params', async () => {
    const variables = {
      organizationId: 10,
      limit: undefined,
      offset: undefined,
      search: undefined,
      getAllEntities: undefined,
      orderBy: undefined,
    };

    const expectedQuery = gql`
      query getEntitiesByOrganizationId(
        $organizationId: ID!
        $limit: Int
        $offset: Int
        $search: String
        $getAllEntities: Boolean
        $orderBy: OrderByInput
      ) {
        node(id: $organizationId) {
          ... on Entity {
            id: dbId
            accessNetwork(networkId: $organizationId) {
              markers {
                ids
                all
                exceptionIds
              }
              organizations {
                ids
                all
                exceptionIds
              }
            }
            entities(
              limit: $limit
              offset: $offset
              search: $search
              getAllEntities: $getAllEntities
              orderBy: $orderBy
            ) {
              totalCount
              nodes {
                id: dbId
                fullname
                type
                picture {
                  uri
                }
                all
                exceptionIds {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;

    await getEntitiesByOrganizationIdRepository(variables);
    expect(DataSource.Query).toHaveBeenCalledWith(expectedQuery, variables);
  });

  it('should return data and errors', async () => {
    const variables = {
      organizationId: 10,
      limit: undefined,
      offset: undefined,
      search: undefined,
      getAllEntities: undefined,
      orderBy: undefined,
    };

    const response = await getEntitiesByOrganizationIdRepository(variables);

    expect(response).toEqual({ data: { nodes: [{ id: 1 }] } });
  });
});
