import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
 * @typedef {{
 * organizationId: number | string;
 * entityId: number | string;
 * search?: string;
 * limit?: number;
 * offset?: number;
 * }} IGetMarkersByOrganizationIdVariables
 */

/**
 * @typedef {{
 *  node: {
 *   markers: {
 *    totalCount: number;
 *   },
 *  markerCollections: {
 *   nodes: {
 *    id: number | string;
 *    name: string;
 *    markers: {
 *      id: number | string;
 *      name: string;
 *    }[] | null;
 *    }[],
 *    totalCount: number;
 *  },
 * }}} IGetMarkersByOrganizationIdFields
 **/

/**
 * @param {IGetMarkersByOrganizationIdVariables} variables
 */
export async function getMarkersByOrganizationIdRepository(variables) {
  const query = gql`
    query GetMarkersByOrganizationId(
      $organizationId: ID!
      $entityId: ID!
      $offset: Int
      $limit: Int
      $search: String
    ) {
      node(id: $organizationId) {
        ... on Network {
          markers(entityId: $entityId) {
            totalCount
          }
          markerCollections(offset: $offset, limit: $limit, search: $search) {
            nodes {
              id
              name
              markers(entityId: $entityId, networkId: $organizationId) {
                id
                name
              }
            }
            totalCount
          }
        }
      }
    }
  `;

  const input = {
    ...variables,
  };

  return Query(query, input);
}
