import React from 'react';
import {
  SelectRecipientModalProvider,
  useSelectRecipientModal,
} from '../useSelectRecipientModal';
import { renderHook } from '@testing-library/react-hooks/dom';

const wrapper = ({ children }) => (
  <SelectRecipientModalProvider
    markersState={{
      excludeMarkers: [],
      setExcludeMarkers: jest.fn(),
      excludeOrganizations: [],
      setExcludeOrganizations: jest.fn(),
      canAccessAllMarkers: false,
      setCanAccessAllMarkers: jest.fn(),
      canAccessAllOrganizations: false,
      setCanAccessAllOrganizations: jest.fn(),
      markers: [],
      setMarkers: jest.fn(),
      organizations: [],
      setOrganizations: jest.fn(),
      organizationsTotalCount: 0,
      setOrganizationsTotalCount: jest.fn(),
      markersTotalCount: 0,
      setMarkersTotalCount: jest.fn(),
    }}
  >
    {children}
  </SelectRecipientModalProvider>
);

describe('useSelectRecipientModal', () => {
  it('should return default values in markers state', () => {
    const { result } = renderHook(() => useSelectRecipientModal(), {
      wrapper,
    });

    expect(result.current.markersState).toStrictEqual({
      excludeMarkers: [],
      excludeOrganizations: [],
      canAccessAllMarkers: false,
      canAccessAllOrganizations: false,
      markers: [],
      organizations: [],
      setExcludeMarkers: expect.any(Function),
      setExcludeOrganizations: expect.any(Function),
      setCanAccessAllMarkers: expect.any(Function),
      setCanAccessAllOrganizations: expect.any(Function),
      setMarkers: expect.any(Function),
      setOrganizations: expect.any(Function),
      markersTotalCount: 0,
      organizationsTotalCount: 0,
      setMarkersTotalCount: expect.any(Function),
      setOrganizationsTotalCount: expect.any(Function),
    });
  });
});
