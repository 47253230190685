import React, { useState, useCallback, useRef } from 'react';
import moment from 'moment';

import { ViewUI } from './ViewUI';
import { useSnackbar } from '../../../../../components/kit/Snackbar/hooks/useSnackbar';
import { createStore } from '../../../../../store';
import { ViewModel as useViewModel } from './ViewModel';
import { hasEntityInCurrentOrganization } from '../../../../../utils';
import { __ } from '../../../../../i18n';
import { hasConversationProductFeature } from '../../../../../helpers/hasConversationProductFeature';

function ViewController(props) {
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    fullname: '',
    externalId: '',
    description: '',
    picture: null,
    type: 'WITH_STATUS',
    status: 'ENABLED',
    hours: {
      open: '',
      close: '',
    },
    selectedUsers: [],
    visibility: {
      status: 'public',
      selectedGroups: [],
    },
    permissions: {
      scope: {
        messages: {
          read: false,
          delete: false,
        },
        reports: {
          read: false,
          createUpdate: false,
          delete: false,
        },
        entities: {
          read: false,
          createUpdate: false,
          delete: false,
          contentApprover: false,
        },
        dashboard: {
          read: false,
        },
        integration: {
          read: false,
        },
        payments: {
          read: false,
        },
        accounts: {
          read: false,
          createUpdate: false,
          delete: false,
        },
        organization: {
          update: false,
        },
        forms: {
          read: false,
          createUpdate: false,
          delete: false,
        },
        links: {
          read: false,
          createUpdate: false,
          delete: false,
        },
      },
      policy: [],
    },
  };

  const {
    createChannel,
    hasMessageApprove,
    convertAddressesToInput,
    convertGroupsToInput,
    uploadChannelPicture,
    createOrUpdateAutomaticMessages,
  } = useViewModel();

  const store = createStore();
  const { toast } = useSnackbar();
  const automaticMessagesRef = useRef(null);

  const { router } = props;

  const organizationId = router?.params?.organization_id;

  const __hasMessageApprove = hasMessageApprove({
    unleashStatus: store?.currentOrganization?.unleashStatus,
    plan: store?.currentOrganization?.plan,
  });

  const hasMenuConversations =
    store?.currentOrganization?.unleashStatus?.web_menu_conversations &&
    hasConversationProductFeature(store.currentOrganization);

  const canAddPicture =
    hasEntityInCurrentOrganization(store, ['ADMIN']) ||
    store?.currentUser?.isMaster;
  const currentEntityId = store?.currentEntity?.id;

  const onSubmit = useCallback(
    async (data) => {
      try {
        const automaticMessages =
          automaticMessagesRef.current?.getAutomaticMessages();
        setIsLoading(true);

        const {
          fullname,
          externalId,
          description,
          hours,
          permissions,
          picture,
          selectedUsers,
          visibility,
          type,
        } = data;

        const ntfStartTime = hours?.open
          ? moment(hours?.open, 'HH:mm').format('HH:mm:00')
          : null;
        const ntfEndTime = hours?.close
          ? moment(hours?.close, 'HH:mm').format('HH:mm:00')
          : null;
        const isApprover = !!permissions?.scope?.entities?.contentApprover;
        const scope = isApprover ? ['CONTENT_APPROVER'] : [];
        const { addresses } = convertAddressesToInput({
          selectedUsers,
        });
        const seeAll = visibility?.status === 'public';
        const groups = seeAll
          ? []
          : convertGroupsToInput(visibility?.selectedGroups);
        const policy = permissions?.policy;

        const confAttendanceEnabled = type === 'WITH_STATUS';

        const result = await createChannel({
          organizationId,
          fullname,
          eid: externalId,
          type: 'STAFF',
          description,
          invisible: false,
          seeAll,
          ntfStartTime,
          ntfEndTime,
          groups,
          defaultFeatures: ['MESSAGE'],
          updateIfExists: false,
          policy,
          confAttendanceEnabled: hasMenuConversations
            ? confAttendanceEnabled
            : undefined,
          scope,
          addresses,
        });

        if (picture) {
          await uploadChannelPicture(picture, {
            entityId: result?.data?.createEntity?.entity?.id,
          });
        }

        if (result.errors) {
          console.log('result.errors: ', result.errors);
          toast({ content: result.errors, type: 'ERROR' });
        } else {
          await createOrUpdateAutomaticMessages({
            entity: {
              ...result?.data?.createEntity?.entity,
              automaticMessages: null,
            },
            automaticMessages,
          });
          toast({ content: __('%s was added', fullname), type: 'SUCCESS' });
          router.push(`/organizations/${organizationId}/channels`);
        }
      } catch (error) {
        let errorMessage = '';
        switch (error?.message) {
        case 'GraphQL error: Entity already exists':
          errorMessage = __(
            'You cannot have channels with the same name and same ID',
          );
          break;
        default:
          errorMessage = error.message;
            break;
        }
        toast({ content: errorMessage, type: 'ERROR' });
      } finally {
        setIsLoading(false);
      }
    },
    [automaticMessagesRef.current],
  );

  return (
    <ViewUI
      onBack={() => {
        router.goBack();
      }}
      isLoading={isLoading}
      currentEntityId={currentEntityId}
      canAddPicture={canAddPicture}
      defaultValues={defaultValues}
      entity={null}
      hasMessageApprove={__hasMessageApprove}
      hasMenuConversations={hasMenuConversations}
      onSubmit={onSubmit}
      ref={automaticMessagesRef}
    />
  );
}

export default ViewController;
