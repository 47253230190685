import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';

import { ViewUI } from './ViewUI';
import { useSnackbar } from '../../../../../components/kit/Snackbar/hooks/useSnackbar';
import { createStore } from '../../../../../store';
import { ViewModel as useViewModel } from './ViewModel';
import { hasEntityInCurrentOrganization } from '../../../../../utils';
import { __ } from '../../../../../i18n';
import { VisibilityStatusEnum } from './types/VisibilityStatusEnum.types';
import { hasConversationProductFeature } from '../../../../../helpers/hasConversationProductFeature';
import { cloneDeep } from 'lodash';

function ViewController(props) {
  const [entity, setEntity] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [defaultAutomaticMessages, setDefaultAutomaticMessages] = useState([]);
  const automaticMessagesRef = useRef(null);

  const {
    getChannel,
    getSelectedUsers,
    hasMessageApprove,
    getVisibilityType,
    isDisabled,
    updateChannel,
    convertAddressesToInput,
    convertGroupsToInput,
    uploadChannelPicture,
    deleteChannelPicture,
    removeIdsFromChannel,
    getDefaultValues,
    createOrUpdateAutomaticMessages,
  } = useViewModel();

  const store = createStore();
  const { toast } = useSnackbar();

  const { router } = props;

  const previousUrl = store.history[store.history.length - 2];
  const redirectUrl =
    previousUrl || `/organizations/${store.currentOrganization?.id}/channels`;
  const hasMenuConversations =
    store?.currentOrganization?.unleashStatus?.web_menu_conversations &&
    hasConversationProductFeature(store.currentOrganization);

  useEffect(() => {
    const { params } = props;
    const entityId = params.entity_id;

    setIsLoading(true);
    (async () => {
      const { data } = await getChannel({ entityId });
      setEntity(data);

      const formattedDefaultValues = getDefaultValues(data);
      setDefaultValues(formattedDefaultValues);
      setDefaultAutomaticMessages(cloneDeep(data.automaticMessages));
      setIsLoading(false);
    })();
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        const automaticMessages =
          automaticMessagesRef.current?.getAutomaticMessages();
        setIsLoading(true);

        const {
          fullname,
          externalId,
          description,
          hours,
          permissions,
          picture,
          selectedUsers,
          visibility,
          type,
          status,
        } = data;

        const seeAll =
          getVisibilityType({
            seeAll: visibility?.status === VisibilityStatusEnum.PUBLIC,
          }) === VisibilityStatusEnum.PUBLIC;
        const ntfStartTime = hours?.open
          ? moment(hours?.open, 'HH:mm').format('HH:mm:00')
          : null;
        const ntfEndTime = hours?.close
          ? moment(hours?.close, 'HH:mm').format('HH:mm:00')
          : null;

        const isApprover = !!permissions?.scope?.entities?.contentApprover;
        const scope = isApprover ? ['CONTENT_APPROVER'] : [];
        const policy = permissions?.policy;

        const { addresses, removedIds } = convertAddressesToInput({
          selectedUsers,
          originalUsers: getSelectedUsers(entity?.users?.nodes),
          originalAddresses: entity?.addresses?.nodes,
          entityId: entity?.id,
        });

        if (removedIds.length > 0) {
          await removeIdsFromChannel(entity?.id, removedIds);
        }

        if (
          (picture && !picture.uri) ||
          (picture && !entity.picture === null)
        ) {
          const pictureURI = await uploadChannelPicture(picture, {
            entityId: entity?.id,
          });
          entity.picture = pictureURI;
        } else if (!picture && entity?.picture?.uri) {
          await deleteChannelPicture({ entityId: entity?.id });
        }
        const confAttendanceEnabled = type === 'WITH_STATUS';

        const disabled = status === 'DISABLED';

        const result = await updateChannel({
          id: entity?.id,
          fullname,
          eid: externalId,
          description,
          policy,
          scope,
          ntfStartTime,
          ntfEndTime,
          disabled,
          addresses,
          confAttendanceEnabled: hasMenuConversations
            ? confAttendanceEnabled
            : undefined,
          groups: convertGroupsToInput(visibility?.selectedGroups),
          seeAll,
          updateIfExists: false,
        });

        if (result.errors) {
          toast({ content: result.errors, type: 'ERROR' });
        } else {
          const messageSnackbar = {
            saved: __('%s was saved', fullname),
            disabled: __('%s successfully disabled', fullname),
            enabled: __('%s successfully enabled', fullname),
          };

          const messageSelect =
            disabled === entity?.disabled
              ? 'saved'
              : disabled
                ? 'disabled'
                : 'enabled';

          await createOrUpdateAutomaticMessages({
            automaticMessages,
            entity: {
              ...entity,
              automaticMessages: defaultAutomaticMessages,
            },
          });

          toast({ content: messageSnackbar[messageSelect], type: 'SUCCESS' });

          window.location.href = redirectUrl;
        }
      } catch (error) {
        let errorMessage = '';
        switch (error?.message) {
        case 'GraphQL error: Entity already exists':
          errorMessage = __(
            'You cannot have channels with the same name and same ID',
          );
          break;
        default:
          errorMessage = error.message;
            break;
        }
        toast({ content: errorMessage, type: 'ERROR' });
      } finally {
        setIsLoading(false);
      }
    },
    [automaticMessagesRef.current, defaultAutomaticMessages],
  );

  return (
    <ViewUI
      onBack={() => {
        router.goBack();
      }}
      isLoading={isLoading}
      currentEntityId={store?.currentEntity?.id}
      isDisabled={isDisabled({ entityType: entity?.type })}
      canAddPicture={
        hasEntityInCurrentOrganization(store, ['ADMIN']) ||
        store?.currentUser?.isMaster
      }
      defaultValues={defaultValues}
      entity={entity}
      hasMessageApprove={hasMessageApprove({
        unleashStatus: store?.currentOrganization?.unleashStatus,
        plan: store?.currentOrganization?.plan,
      })}
      hasMenuConversations={hasMenuConversations}
      ref={automaticMessagesRef}
      onSubmit={onSubmit}
    />
  );
}

export default ViewController;
