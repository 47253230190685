import { getEntitiesByOrganizationIdRepository } from '../../../Data/Repository/Permissions/GetEntitiesByOrganizationId';

export async function getEntitiesByOrganizationIdUseCase(variables) {
  try {
    const response = await getEntitiesByOrganizationIdRepository(variables);

    return {
      id: response.data.node.id,
      accessNetwork: {
        markers: response.data.node.accessNetwork.markers,
        organizations: response.data.node.accessNetwork.organizations,
      },
      entities: {
        nodes: response.data.node.entities.nodes.map((node) => ({
          id: node.id,
          fullname: node.fullname,
          type: node.type,
          picture: node.picture.uri,
          all: node.all,
          exceptionIds: node.exceptionIds,
        })),
        totalCount: response.data.node.entities.totalCount,
      },
    };
  } catch (error) {
    return {
      error: error.message,
    };
  }
}
