/**
 * @param {{
 *    id: number,
 *    unleashStatus: {
 *      custom_media_validation: boolean
 *      custom_media: boolean
 *    }
 * }} organization
 */
export function hasCustomFileValidation(organization) {
  if (!organization) return false;

  const hasCustomFileFeature = organization?.unleashStatus?.custom_media;
  const hasCustomFileValidation =
    organization?.unleashStatus?.custom_media_validation;

  return hasCustomFileFeature && hasCustomFileValidation;
}
