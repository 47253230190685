import { renderHook, act } from '@testing-library/react-hooks/dom';
import { ViewModel as useViewModel } from '../ViewModel';
import * as OrganizationShouldSeeConversationHelper from '../../../../../../helpers/organizationShouldSeeConversation';
import * as GetOrganizationUnleashUseCase from '../../../../../Domain/UseCases/Unleash/api/GetOrganizationUnleashUseCase';
import { getOrganizationUnleashMock } from '../../../../../Data/Repository/Unleash/__mocks__/getOrganizationUnleash.mock';

describe('Chats ViewModel', () => {
  const mockedProps = {
    currentOrganization: {
      id: 1,
      plan: 'PREMIUM',
      productFeatures: {
        nodes: [{ identifier: 'attendanceChannel' }],
      },
    },
    unleash: {
      toggles: [
        {
          name: 'web_menu_conversations',
        },
        {
          name: 'menu_conversations_organization_direct_list',
        },
      ],
    },
    currentEntity: {
      organization: {
        permissions: {
          entityScopes: ['READ_MESSAGE'],
        },
      },
      type: 'STAFF',
    },
    currentUser: {
      isMaster: false,
    },
    router: {
      replace: jest.fn(),
      push: jest.fn(),
    },
  };

  beforeEach(() => {
    jest
      .spyOn(GetOrganizationUnleashUseCase, 'getOrganizationUnleashUseCase')
      .mockResolvedValue(getOrganizationUnleashMock);
  });

  it('should be able to NOT execute the redirect if user can see the directs manager page', async () => {
    const { result } = renderHook(() => useViewModel());

    act(() => {
      result.current.redirectToLastPageIfNotHasAccess(mockedProps);
    });

    expect(mockedProps.router.replace).not.toHaveBeenCalled();
  });

  it('should be able to execute the redirect if user can NOT see the directs manager page', async () => {
    mockedProps.currentEntity.organization.permissions.entityScopes = [];
    const { result } = renderHook(() => useViewModel());

    act(() => {
      result.current.redirectToLastPageIfNotHasAccess(mockedProps);
    });

    expect(mockedProps.router.replace).toHaveBeenCalled();
  });

  it('should be able to call the organizationShouldSeeConversation helper with the correct params', async () => {
    const spy = jest
      .spyOn(
        OrganizationShouldSeeConversationHelper,
        'organizationShouldSeeConversation',
      )
      .mockReturnValue(true);
    const { result } = renderHook(() => useViewModel());

    act(() => {
      result.current.redirectToLastPageIfNotHasAccess(mockedProps);
    });

    expect(spy).toHaveBeenCalledWith({
      currentOrganization: mockedProps.currentOrganization,
      unleash: mockedProps.unleash,
      currentEntity: mockedProps.currentEntity,
      isMaster: mockedProps.currentUser.isMaster,
    });
  });

  it('should be able to execute get organization unleash use case', async () => {
    const { result } = renderHook(() => useViewModel());

    await act(async () => {
      await result.current.getOrganizationUnleashFeature(1);
    });

    expect(result.current.isLoading).toBe(false);
    expect(
      GetOrganizationUnleashUseCase.getOrganizationUnleashUseCase,
    ).toHaveBeenCalledWith(1);
  });

  it('should be able to see the loading as true by default', () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.isLoading).toBe(true);
  });
});
