import * as repository from '../../../../Data/Repository/Permissions/GetMarkersByOrganizationId';
import { getMarkersByOrganizationIdUseCase } from '../GetMarkersByOrganizationId';

describe('given getMarkersByOrganizationId use case', () => {
  const mocks = {
    organizationId: '1',
    search: '',
    limit: 30,
    offset: 0,
  };

  beforeAll(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    jest
      .spyOn(repository, 'getMarkersByOrganizationIdRepository')
      .mockResolvedValue(
        Promise.resolve({
          id: '1',
          fullname: 'John Doe',
        }),
      );
  });

  it('should be able to call use case correctly', async () => {
    const result = await getMarkersByOrganizationIdUseCase(mocks);
    expect(result).toStrictEqual({
      id: '1',
      fullname: 'John Doe',
    });
  });
});
