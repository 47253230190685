import { hasCustomFileValidation } from '../HasCustomFileValidation';

describe('hasCustomFileValidation', () => {
  let organization;

  beforeEach(() => {
    jest.clearAllMocks();

    organization = {
      id: 1,
      unleashStatus: {
        custom_media_validation: true,
        custom_media: true,
      },
    };
  });

  it('should return false when organization is not provided', () => {
    expect(hasCustomFileValidation()).toBe(false);
  });

  it('should return true when organization has custom media feature and validation', () => {
    expect(hasCustomFileValidation(organization)).toBe(true);
  });

  it('should return false when organization has feature and not has validiation', () => {
    organization.unleashStatus.custom_media_validation = false;
    expect(hasCustomFileValidation(organization)).toBe(false);
  });

  it('should return false when organization has not feature and has validation', () => {
    organization.unleashStatus.custom_media = false;
    expect(hasCustomFileValidation(organization)).toBe(false);
  });
});
