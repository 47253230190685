import React, { createContext, useContext, useMemo } from 'react';

/**
  * @typedef {{
  *  organizations: import('@classapp-tech/edna').OptionItem[];
  *  setOrganizations: React.Dispatch<React.SetStateAction<import('@classapp-tech/edna').OptionItem[]>>;
  *  markers: import('@classapp-tech/edna').OptionItem[];
  *  setMarkers: React.Dispatch<React.SetStateAction<import('@classapp-tech/edna').OptionItem[]>>;
  *  canAccessAllOrganizations: boolean;
  *  setCanAccessAllOrganizations: React.Dispatch<React.SetStateAction<boolean>>;
  *  canAccessAllMarkers: boolean;
  *  setCanAccessAllMarkers: React.Dispatch<React.SetStateAction<boolean>>;
  *  excludeOrganizations: import('@classapp-tech/edna').OptionItem[];
  *  setExcludeOrganizations: React.Dispatch<React.SetStateAction<import('@classapp-tech/edna').OptionItem[]>>;
  *  excludeMarkers: import('@classapp-tech/edna').OptionItem[];
  *  setExcludeMarkers: React.Dispatch<React.SetStateAction<import('@classapp-tech/edna').OptionItem[]>>;
  *  markersTotalCount: number;
  *  setMarkersTotalCount: React.Dispatch<React.SetStateAction<number>>;
  *  organizationsTotalCount: number;
  *  setOrganizationsTotalCount: React.Dispatch<React.SetStateAction<number>>;
  * }} markersState

  * @param {markersState} markersState
*/

/**
 * @typedef {{
 *  markersState: markersState;
 * }} SelectRecipientModalProps
 */

/**
 * @typedef {{
 *  markersState: markersState;
 *  setMarkersState: (state: markersState) => void;
 *  children: React.ReactNode;
 * }} SelectRecipientModalProviderProps
 *
 */

const SelectRecipientModalContext = createContext();

/**
 * @param {SelectRecipientModalProviderProps} props
 */
export function SelectRecipientModalProvider(props) {
  const { markersState, setMarkersState } = props;

  return useMemo(
    () => (
      <SelectRecipientModalContext.Provider
        value={{
          markersState,
          setMarkersState,
        }}
      >
        {props.children}
      </SelectRecipientModalContext.Provider>
    ),
    [markersState],
  );
}

export const useSelectRecipientModal = () =>
  useContext(SelectRecipientModalContext);
