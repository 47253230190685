import * as ApolloDataSource from '../../../../../Data/DataSource/ApolloDataSource';
import { getOrganizationUnleashUseCase } from '../GetOrganizationUnleashUseCase';

describe('given getOrganizationUnleashUseCase use case', () => {
  const mocks = {
    id: 1,
  };

  beforeAll(() => {
    jest
      .spyOn(ApolloDataSource, 'Query')
      .mockImplementation(async () => ({ data: { id: 1 } }));
  });

  it('should be able to get an channel node', async () => {
    const result = await getOrganizationUnleashUseCase(mocks);
    expect(result?.data?.id).not.toBeFalsy();
  });
});
