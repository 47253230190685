import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
 * @typedef {{
 *  node: {
 *   organizations: {
 *    nodes:
 *     {
 *      id: number | string;
 *      fullname: string;
 *      logo: {
 *       uri: string;
 *      };
 *     }[];
 *    totalCount: number;
 *   }
 *  }
 *  error?: string[];
 * }} IGetOrganizationsByNetworkIdFields
 */

/**
 * @typedef {{
 *  organizationId: number | string;
 *  entityId: number | string;
 *  search?: string;
 *  limit?: number;
 *  offset?: number;
 * }} IGetOrganizationsByNetworkIdVariables
 */

/**
 * @param {IGetOrganizationsByNetworkIdVariables} variables
 * @returns {Promise<IGetOrganizationsByNetworkIdFields>}
 */
export async function getOrganizationsByNetworkIdRepository(variables) {
  const query = gql`
    query WalleGetOrganizationsByNetworkId(
      $organizationId: ID!
      $entityId: ID!
      $search: String
      $limit: Int
      $offset: Int
    ) {
      node(id: $organizationId) {
        ... on Network {
          organizations(
            entityId: $entityId
            name: $search
            limit: $limit
            offset: $offset
            showSelfOrganization: true
          ) {
            nodes {
              id: dbId
              fullname
              logo {
                uri
              }
            }
            totalCount
          }
        }
      }
    }
  `;

  try {
    const { data, error } = await Query(query, variables);
    return { data, error };
  } catch (err) {
    console.error('Error fetching organizations: ', err);
    return { data: null, error: err.message };
  }
}
