import { getOrganizationsByNetworkIdRepository } from '../../../Data/Repository/Permissions/getOrganizationsByNetworkIdRepository';

/**
 * @param {import("../../../Data/Repository/Permissions/getOrganizationsByNetworkIdRepository").IGetOrganizationsByNetworkIdVariables} variables
 * @returns
 */
export async function getOrganizationsByNetworkIdUseCase(variables) {
  const { data, error } =
    await getOrganizationsByNetworkIdRepository(variables);
  return { data, error };
}
