import { useState } from 'react';
import { organizationShouldSeeConversation } from '../../../../../helpers/organizationShouldSeeConversation';
import { getOrganizationUnleashUseCase } from '../../../../Domain/UseCases/Unleash/api/GetOrganizationUnleashUseCase';

export function ViewModel() {
  const [isLoading, setIsLoading] = useState(true);

  async function getOrganizationUnleashFeature(orgId) {
    try {
      return await getOrganizationUnleashUseCase(orgId);
    } finally {
      setIsLoading(false);
    }
  }

  function redirectToLastPageIfNotHasAccess({
    currentOrganization,
    currentEntity,
    unleash,
    currentUser,
    router,
  }) {
    const shouldSeeConversations = organizationShouldSeeConversation({
      currentOrganization,
      unleash,
      currentEntity: currentEntity,
      isMaster: currentUser?.isMaster,
    });

    if (!shouldSeeConversations) {
      router.replace(`/organizations/${currentOrganization?.id}`);
    }
  }

  return {
    redirectToLastPageIfNotHasAccess,
    getOrganizationUnleashFeature,
    isLoading,
  };
}
